import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
 apiKey: "AIzaSyDNumAWZRd9ida-ihlbVTXLa-wNOO2SiWw",
 authDomain: "h2r-stinger.firebaseapp.com",
 projectId: "h2r-stinger",
 storageBucket: "h2r-stinger.appspot.com",
 messagingSenderId: "514700991644",
 appId: "1:514700991644:web:695670a952140cd77f6159",
 measurementId: "G-RN0YZ39BCW",
};

firebase.initializeApp(firebaseConfig);
export const analytics = firebase.analytics();
