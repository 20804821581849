import store from "./store";

export const updateRunStinger = (running) => {
 return store.dispatch({
  type: "UPDATE_RUNNING_STINGER",
  running,
 });
};

export const updateRunStingerStep = (step) => {
 return store.dispatch({
  type: "UPDATE_RUNNING_STINGER_STEP",
  step,
 });
};

export const updateRunningWaiting = (waiting) => {
 return store.dispatch({
  type: "UPDATE_RUNNING_WAITING",
  waiting,
 });
};

export const cut = () => {
 return store.dispatch({
  type: "CUT",
 });
};

export const updateStingerType = (stinger) => {
 return store.dispatch({
  type: "UPDATE_STINGER_TYPE",
  stinger,
 });
};

export const updateStingerColour = (colour) => {
 return store.dispatch({
  type: "UPDATE_STINGER_COLOUR",
  colour,
 });
};

export const updateStingerWait = (wait) => {
 return store.dispatch({
  type: "UPDATE_STINGER_WAIT",
  wait,
 });
};

export const updateStingerUserWait = (userWait) => {
 return store.dispatch({
  type: "UPDATE_STINGER_USER_WAIT",
  userWait,
 });
};

export const updateDsk = (dsk) => {
 return store.dispatch({
  type: "UPDATE_DSK",
  dsk,
 });
};

export const updateColourGenerator = (colourGenerator) => {
 return store.dispatch({
  type: "UPDATE_COLOUR_GENERATOR",
  colourGenerator,
 });
};

export const updateMacroIndex = (macroIndex) => {
 return store.dispatch({
  type: "UPDATE_MACRO_INDEX",
  macroIndex,
 });
};

export const updateMediaPlayer = (mediaPlayer) => {
 return store.dispatch({
  type: "UPDATE_MEDIA_PLAYER",
  mediaPlayer,
 });
};

export const updateUseStills = (useStills) => {
 return store.dispatch({
  type: "UPDATE_USE_STILLS",
  useStills,
 });
};

export const updateMacroXML = (xml) => {
 return store.dispatch({
  type: "UPDATE_MACRO_XML",
  xml,
 });
};

export const updateMediaPoolXML = (xml) => {
 return store.dispatch({
  type: "UPDATE_MEDIA_POOL_XML",
  xml,
 });
};
