import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./components/Home";
import Support from "./components/Support";

export default function App() {
 return (
  <Router>
   <Switch>
    <Route path="/support">
     <Support />
    </Route>
    <Route path="/">
     <Home />
    </Route>
   </Switch>
  </Router>
 );
}
