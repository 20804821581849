import { Link } from "react-router-dom";

export default function Layout({ children }) {
 return (
  <div className="flex flex-col justify-between min-h-screen">
   <Header />
   <div className="grow">{children}</div>
   <Footer />
  </div>
 );
}

export const SectionWrapped = ({
 bgColour = "bg-gray-800",
 py = "py-0",
 children,
}) => {
 return (
  <div className={`${bgColour} text-gray-100`}>
   <main className={`${py} px-3 max-w-6xl mx-auto `}>{children}</main>
  </div>
 );
};

function Header() {
 return (
  <header className="bg-purple-600 py-2">
   <div className="px-3 max-w-6xl mx-auto flex justify-between items-center">
    <div className="flex flex-col items-start">
     <Link to="/">
      <h1 className="font-bold">H2R Stingers</h1>

      <span className="text-sm text-purple-300">
       Stingers for your live production
      </span>
     </Link>
    </div>
    <div className="flex flex-col text-right sm:flex-row sm:space-x-2 items-end">
     <Link
      className="text-sm md:text-base text-purple-300 hover:text-purple-200"
      to="/support"
     >
      <span>Support project</span>
     </Link>
     <span className="text-purple-300 hidden sm:block">|</span>
     <a
      className="text-sm md:text-base text-purple-300 hover:text-purple-200"
      href="https://heretorecord.gitbook.io/h2r-stingers/"
      target="_blank"
      rel="noreferrer"
     >
      <span>Docs</span>
     </a>
    </div>
   </div>
  </header>
 );
}

function Footer() {
 return (
  <footer className="mt-auto bg-purple-500 text-white py-4 text-sm">
   <div className="px-3 max-w-6xl mx-auto">
    Made with ❤️ and ☕️ by{" "}
    <a
     className="hover:underline"
     href="https://heretorecord.com/"
     target="_blank"
     rel="noreferrer"
    >
     Here to Record
    </a>
    .
    <a
     className="ml-2 hover:underline"
     href="https://heretorecord.gitbook.io/h2r-stingers/"
     target="_blank"
     rel="noreferrer"
    >
     Check our docs
    </a>
    .
    <a
     className="ml-2 hover:underline"
     href="https://heretorecord.com/issue"
     target="_blank"
     rel="noreferrer"
    >
     Issue or feedback?
    </a>
    .
   </div>
  </footer>
 );
}
