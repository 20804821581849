const initialState = {
 runningStinger: false,
 runningStingerStep: -1,
 waiting: false,
 program: 1,
 preview: 2,
 stingerType: "circle-to-center",
 defaultStingerColour: "#d92828",
 stingerColour: {
  h: 0,
  s: 0.6803898504453034,
  l: 0.49613486999999995,
  a: 1,
 },
 stingerWait: 10,
 userWait: false,
 dsk: 1,
 colourGenerator: 1,
 macroIndex: 0,
 mediaPlayer: 1,
 useStills: 1,
 macroXML: "",
 mediaPoolXML: "",
};

export default function rootReducer(state = initialState, action) {
 switch (action.type) {
  case "UPDATE_RUNNING_STINGER":
   return {
    ...state,
    runningStinger: action.running,
   };

  case "UPDATE_RUNNING_STINGER_STEP":
   return {
    ...state,
    runningStingerStep: action.step,
   };

  case "UPDATE_RUNNING_WAITING":
   return {
    ...state,
    waiting: action.waiting,
   };

  case "CUT":
   return {
    ...state,
    program: state.program === 1 ? 2 : 1,
    preview: state.preview === 1 ? 2 : 1,
   };

  case "UPDATE_STINGER_TYPE":
   return {
    ...state,
    stingerType: action.stinger,
   };

  case "UPDATE_STINGER_COLOUR":
   return {
    ...state,
    stingerColour: action.colour,
   };

  case "UPDATE_STINGER_WAIT":
   return {
    ...state,
    stingerWait: action.wait,
   };

  case "UPDATE_STINGER_USER_WAIT":
   return {
    ...state,
    userWait: action.userWait,
   };

  case "UPDATE_DSK":
   return {
    ...state,
    dsk: action.dsk,
   };

  case "UPDATE_COLOUR_GENERATOR":
   return {
    ...state,
    colourGenerator: action.colourGenerator,
   };

  case "UPDATE_MACRO_INDEX":
   return {
    ...state,
    macroIndex: action.macroIndex,
   };

  case "UPDATE_MEDIA_PLAYER":
   return {
    ...state,
    mediaPlayer: action.mediaPlayer,
   };

  case "UPDATE_USE_STILLS":
   return {
    ...state,
    useStills: action.useStills,
   };

  case "UPDATE_MACRO_XML":
   return {
    ...state,
    macroXML: action.xml,
   };

  case "UPDATE_MEDIA_POOL_XML":
   return {
    ...state,
    mediaPoolXML: action.xml,
   };

  default:
   return state;
 }
}
