export default function HowItWorks() {
 return (
  <article className="prose lg:prose-xl ">
   <h2>How it works</h2>
   <h3>Create</h3>
   <p>
    Choose your stinger type and colour above - When you’re happy with the
    settings, it’s time to download...
   </p>
   <h3>Import</h3>
   <p>
    In the ATEM Software control, use the <strong>“Save As…”</strong> option to
    save an XML file of your settings. You’ll only need Macros and the Media
    Pool for this. Open the exported file and paste-in the downloaded XML code
    from each .xml file.
   </p>
   <p>
    You’ll be importing a <strong>Macro</strong> and a collection of images into
    your <strong>Media Pool</strong>.
   </p>
   <p>
    Full instructions can be{" "}
    <a
     href="https://heretorecord.gitbook.io/h2r-stingers/"
     target="_blank"
     rel="noreferrer"
    >
     found in the tutorials
    </a>
    .
   </p>
   <h3>Sting</h3>
   <p>
    You’re good to go. Run your macro a few times to test, otherwise,{" "}
    <strong>have a great show</strong>!
   </p>
  </article>
 );
}
