import { useSelector } from "react-redux";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import imageToBase64 from "image-to-base64/browser";

import H2RButton from "./h2r-components/H2RButton";
import { stingers } from "../store/definitions";
import { analytics } from "../store/firebase";

export default function ExportFile() {
 let stingerType = useSelector((state) => state.stingerType);
 let macroXML = useSelector((state) => state.macroXML);
 let mediaPoolXML = useSelector((state) => state.mediaPoolXML);

 const handleOnDownload = () => {
  let zip = new JSZip();

  let macroFile = new File([macroXML], "Macro.xml", {
   type: "text/xml",
  });
  zip.file("macro.xml", macroFile);

  let mediaPoolFile = new File([mediaPoolXML], "Media Pool.xml", {
   type: "text/xml",
  });
  zip.file("mediaPool.xml", mediaPoolFile);

  let img1 = imageToBase64(stingers[stingerType].pngs[0]).then((res) => res);
  let img2 = imageToBase64(stingers[stingerType].pngs[1]).then((res) => res);
  let img3 = imageToBase64(stingers[stingerType].pngs[2]).then((res) => res);
  let img4 = imageToBase64(stingers[stingerType].pngs[3]).then((res) => res);
  let img5 = imageToBase64(stingers[stingerType].pngs[4]).then((res) => res);
  let img6 = imageToBase64(stingers[stingerType].pngs[5]).then((res) => res);
  let img7 = imageToBase64(stingers[stingerType].pngs[6]).then((res) => res);
  let img8 = imageToBase64(stingers[stingerType].pngs[7]).then((res) => res);
  let img9 = imageToBase64(stingers[stingerType].pngs[8]).then((res) => res);
  let img10 = imageToBase64(stingers[stingerType].pngs[9]).then((res) => res);

  zip.file("ATEM Media Pool/1.png", img1, { base64: true });
  zip.file("ATEM Media Pool/2.png", img2, { base64: true });
  zip.file("ATEM Media Pool/3.png", img3, { base64: true });
  zip.file("ATEM Media Pool/4.png", img4, { base64: true });
  zip.file("ATEM Media Pool/5.png", img5, { base64: true });
  zip.file("ATEM Media Pool/6.png", img6, { base64: true });
  zip.file("ATEM Media Pool/7.png", img7, { base64: true });
  zip.file("ATEM Media Pool/8.png", img8, { base64: true });
  zip.file("ATEM Media Pool/9.png", img9, { base64: true });
  zip.file("ATEM Media Pool/10.png", img10, { base64: true });

  zip.generateAsync({ type: "blob" }).then(function (content) {
   // see FileSaver.js
   saveAs(content, `H2R Stingers - ${stingers[stingerType].label}.zip`);
  });

  //   FileSaver.saveAs(macroFile);

  //   FileSaver.saveAs(mediaPoolFile);
 };
 return (
  <H2RButton
   text="Download"
   icon="download"
   colour="dark"
   size="small"
   css="h-min w-min"
   onClick={() => {
    analytics.logEvent("download", {
     stingerType: stingerType,
    });
    handleOnDownload();
   }}
  />
 );
}
