import { useEffect } from "react";
import { useSelector } from "react-redux";

import JohnListeningVideo from "../img/john_apartment_listening_hd_stinger_30_secs.webm";
import JohnTalkingVideo from "../img/john_office_talking_hd_stinger_30secs.webm";
import JohnListeningImage from "../img/john-listening.jpg";
import JohnTalkingImage from "../img/john-talking.jpg";

import { stingers } from "../store/definitions";
import * as actions from "../store/actions";

export default function Multiview() {
 let stingerColour = useSelector((state) => state.stingerColour);
 let colourGenerator = useSelector((state) => state.colourGenerator);
 let mediaPlayer = useSelector((state) => state.mediaPlayer);

 let runningStinger = useSelector((state) => state.runningStinger);

 let stingerWait = useSelector((state) => state.stingerWait);
 let userWait = useSelector((state) => state.userWait);
 let waiting = useSelector((state) => state.waiting);
 let preview = useSelector((state) => state.preview);

 useEffect(() => {
  if (runningStinger) {
   var i = 0;

   function stingIn() {
    if (waiting) {
     i = 10;
     actions.updateRunningWaiting(false);
     return stingOut();
    }
    setTimeout(function () {
     i++;
     if (i < 9) {
      actions.updateRunStingerStep(i);
      stingIn();
     } else if (i === 9) {
      actions.updateRunStingerStep(9);
      actions.cut();
      if (userWait) {
       actions.updateRunningWaiting(true);
       actions.updateRunStinger(false);
       return null;
      }
      stingIn();
     } else if (i < 10 + stingerWait) {
      actions.updateRunStingerStep(9);
      stingIn();
     } else {
      stingOut();
     }
    }, 35);
   }
   stingIn();

   function stingOut() {
    setTimeout(function () {
     i--;
     if (i > 9) {
      actions.updateRunStingerStep(9);
      stingOut();
     } else if (i > -1) {
      actions.updateRunStingerStep(i);
      stingOut();
     } else {
      actions.updateRunStingerStep(-1);
      actions.updateRunStinger(false);
     }
    }, 35);
   }
  }
 }, [runningStinger, stingerWait]);

 return (
  <div className="pt-10 pb-4">
   {/* PROGRAM PREVIEW */}
   <div className="flex">
    <div
     //  style={{
     //   backgroundSize: "contain",
     //   background: `url(${
     //    program === 1 ? JohnListeningVideoMP4 : JohnListeningVideoMP4
     //   })`,
     //  }}
     className="relative bg-gray-800 border-t-2 border-l-2 border-r w-1/2 flex justify-center items-end"
    >
     {/* <video
      className="aspect-video"
      src={preview === 2 ? JohnListeningVideo : JohnTalkingVideo}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
     /> */}
     <video
      style={{ objectFit: "initial" }}
      className={`absolute top-0 left-0 max-h-full min-w-full z-10 ${
       preview === 2 ? "opacity-0" : "opacity-100"
      }`}
      src={JohnTalkingVideo}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
     />

     <video
      style={{ objectFit: "initial" }}
      className={`absolute top-0 left-0 max-h-full min-w-full z-10 ${
       preview === 1 ? "opacity-0" : "opacity-100"
      }`}
      src={JohnListeningVideo}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
     />
     <img
      alt="John in studio"
      style={{ objectFit: "initial" }}
      className={`absolute top-0 left-0 max-h-full min-w-full z-0 aspect-video h-full`}
      src={preview === 1 ? JohnTalkingImage : JohnListeningImage}
     />
     <MultiviewLabel label="PREVIEW" absolute={true} />
    </div>

    {/* PGM */}
    <div className="relative aspect-video bg-gray-800 border-t-2 border-r-2 border-l w-1/2 flex justify-center items-end">
     <div
      style={
       {
        //    backgroundSize: "cover",
        //    backgroundImage: `url(${preview === 1 ? JohnListening : JohnTalking})`,
        //  WebkitMaskImage: `url(${stingers[stingerType].steps.[3]})`,
        //  WebkitMaskSize: "100% 100%",
       }
      }
      className="aspect-video bg-gray-800 w-full flex justify-center items-end"
     ></div>
     {/* <video
      className="aspect-video"
      src={preview === 1 ? JohnListeningVideo : JohnTalkingVideo}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
     /> */}

     <video
      style={{ objectFit: "initial" }}
      className={`absolute top-0 left-0 max-h-full min-w-full z-10 ${
       preview === 1 ? "opacity-0" : "opacity-100"
      }`}
      src={JohnTalkingVideo}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
     />
     <video
      style={{ objectFit: "initial" }}
      className={`absolute top-0 left-0 max-h-full min-w-full z-10 ${
       preview === 2 ? "opacity-0" : "opacity-100"
      }`}
      src={JohnListeningVideo}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
     />
     <img
      alt="John in studio"
      style={{ objectFit: "initial" }}
      className={`absolute top-0 left-0 max-h-full min-w-full z-0  aspect-video h-full`}
      src={preview === 2 ? JohnTalkingImage : JohnListeningImage}
     />

     <StingerMask stingerColour={stingerColour} />
     <MultiviewLabel label="PROGRAM" absolute={true} />
    </div>
   </div>

   {/* INPUTS */}
   <div className="flex">
    <div className="relative aspect-video bg-gray-800 border-t-2 border-b-2 border-l-2 border-r w-1/4 flex justify-center items-end">
     <video
      style={{ objectFit: "initial" }}
      className="absolute top-0 right-0 aspect-video max-h-full min-w-full z-10"
      src={JohnListeningVideo}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
     />
     <img
      alt="John in studio"
      style={{ objectFit: "initial" }}
      className={`absolute top-0 left-0 max-h-full min-w-full z-0 aspect-video h-full`}
      src={JohnListeningImage}
     />
     <MultiviewLabel label={"CAMERA 1"} absolute={true} />
    </div>
    <div className="relative bg-gray-800 border-t-2 border-b-2 border-l border-r w-1/4 flex justify-center items-end">
     <video
      style={{ objectFit: "initial" }}
      className="absolute top-0 right-0 aspect-video max-h-full min-w-full z-10"
      src={JohnTalkingVideo}
      autoPlay={true}
      loop={true}
      muted={true}
      playsInline={true}
     />
     <img
      alt="John in studio"
      style={{ objectFit: "initial" }}
      className={`absolute top-0 left-0 max-h-full min-w-full z-0  aspect-video h-full`}
      src={JohnTalkingImage}
     />
     <MultiviewLabel label={"CAMERA 2"} absolute={true} />
    </div>
    <div
     style={{
      backgroundColor: `hsl(${stingerColour.h}, ${stingerColour.s * 100}%, ${
       stingerColour.l * 100
      }%)`,
     }}
     className="aspect-video border-t-2 border-b-2 border-l border-r w-1/4 flex justify-center items-end"
    >
     <MultiviewLabel label={`COLOR ${colourGenerator}`} />
    </div>

    <div className="relative border-t-2 border-b-2 border-r-2 border-l  w-1/4 flex justify-center items-end">
     <div className="aspect-video bg-black w-full flex justify-center items-end z-10"></div>
     <StingerMask stingerColour={"white"} />

     <MultiviewLabel label={`MEDIA PLAYER ${mediaPlayer}`} absolute={true} />
    </div>
   </div>
  </div>
 );
}

const StingerMask = ({ stingerColour }) => {
 let stingerType = useSelector((state) => state.stingerType);

 let runningStingerStep = useSelector((state) => state.runningStingerStep);

 return (
  <div
   style={{
    WebkitMaskImage: `url(${stingers[stingerType].steps[runningStingerStep]})`,
    WebkitMaskSize: "100% 100%",
    backgroundColor:
     stingerColour === "white"
      ? "white"
      : `hsl(${stingerColour.h}, ${stingerColour.s * 100}%, ${
         stingerColour.l * 100
        }%)`,
   }}
   className="absolute z-30 top-0 left-0 aspect-video w-full flex justify-center items-end"
  ></div>
 );
};

const MultiviewLabel = ({ label, absolute }) => {
 return (
  <span
   className={`mb-[0.5vw] px-[.5vw] py-[.2vw] text-[1vw] md:text-[.8rem] bg-black/30 rounded-[.3vw] text-white/70
   ${absolute && "absolute bottom-0 z-40"} 
   `}
  >
   {label}
  </span>
 );
};
