import FAQs from "./FAQs";
import HowItWorks from "./HowItWorks";
import Layout, { SectionWrapped } from "./Layout";
import Macro from "./Macro";
import Multiview from "./Multiview";
import Settings from "./Settings";
import { SupportContent } from "./Support";

export default function Home() {
 return (
  <Layout>
   <SectionWrapped>
    <Multiview />
   </SectionWrapped>
   <SectionWrapped>
    <Settings />
   </SectionWrapped>
   <SectionWrapped>
    <Macro />
   </SectionWrapped>
   <SectionWrapped bgColour="bg-gray-100" py="py-12">
    <HowItWorks />
   </SectionWrapped>
   <SectionWrapped bgColour="bg-gray-200" py="py-12">
    <SupportContent />
   </SectionWrapped>
   <SectionWrapped bgColour="bg-gray-100" py="py-12">
    <FAQs />
   </SectionWrapped>
  </Layout>
 );
}
