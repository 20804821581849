import { useState } from "react";
import { useSelector } from "react-redux";
import { Switch } from "@headlessui/react";

import H2RButton from "./h2r-components/H2RButton";
import H2RInput from "./h2r-components/H2RInput";
import * as actions from "../store/actions";
import StingersDropdown from "./StingersDropdown";
import ExportFile from "./ExportFiles";
import { analytics } from "../store/firebase";

export default function Settings() {
 const [adjustmentOpen, setAdjustmentsOpen] = useState(false);

 let runningStinger = useSelector((state) => state.runningStinger);

 let stingerColour = useSelector((state) => state.stingerColour);
 let stingerWait = useSelector((state) => state.stingerWait);
 let userWait = useSelector((state) => state.userWait);
 let waiting = useSelector((state) => state.waiting);

 let dsk = useSelector((state) => state.dsk);
 let colourGenerator = useSelector((state) => state.colourGenerator);
 let macroIndex = useSelector((state) => state.macroIndex);
 let mediaPlayer = useSelector((state) => state.mediaPlayer);
 let useStills = useSelector((state) => state.useStills);

 return (
  <div className="pb-8">
   {/* TOP LEVEL SETTINGS */}
   <div className="flex flex-col md:flex-row justify-between">
    {/* LEFT */}
    <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 items-baseline sm:items-end">
     <div className="w-full sm:w-[16rem] md:w-[12rem] lg:w-[20rem]">
      <StingersDropdown />
     </div>
     <div className="w-full sm:max-w-[10rem]">
      <H2RInput
       label="Colour"
       size="small"
       colourPicker={true}
       value={typeof stingerColour === "object" ? "#d92828" : stingerColour}
       onChange={(change) => {
        actions.updateStingerColour(change);
       }}
       disabled={true}
      />
     </div>

     <div className="w-full sm:max-w-[6rem]">
      <InputWithInsetLabel
       value={stingerWait}
       label="Mid-sting wait"
       insetLabel="frames"
       min={0}
       max={100}
       onChange={(change) => {
        analytics.logEvent("sting");
        actions.updateStingerWait(change);
       }}
       disabled={userWait}
      />
     </div>

     <H2RButton
      text={"Sting"}
      icon="double_arrow_right"
      size="small"
      colour={waiting ? "main" : runningStinger ? "dark" : "main"}
      disabled={waiting ? false : runningStinger}
      css={`h-min ${waiting && "animate-pulse"}`}
      onClick={() => {
       actions.updateRunStinger(!runningStinger);
      }}
     />
    </div>

    {/* RIGHT */}
    <div className="pl-0 md:pl-2 flex space-x-2 space-y-4 md:space-y-0 items-end justify-end">
     <H2RButton
      colour={adjustmentOpen ? "main" : "dark"}
      text=""
      icon="cog"
      size="small"
      css="h-min w-min"
      onClick={() => setAdjustmentsOpen(!adjustmentOpen)}
     />
     <ExportFile />
    </div>
   </div>
   {/* ADJUSTMENTS */}
   {adjustmentOpen && (
    <>
     <div className="flex flex-col sm:flex-row justify-end">
      <div className="mt-4 px-2 pb-2 flex flex-col sm:flex-row bg-gray-900 sm:space-x-4 whitespace-nowrap items-start sm:items-end rounded">
       <H2RInput
        type="number"
        label="DSK"
        size="small"
        value={dsk}
        onChange={(change) => actions.updateDsk(parseInt(change))}
        min="1"
        max="4"
       />
       <H2RInput
        type="number"
        label="Colour gen."
        size="small"
        value={colourGenerator}
        onChange={(change) => actions.updateColourGenerator(parseInt(change))}
        min="1"
        max="2"
       />
       <H2RInput
        type="number"
        label="Macro index"
        size="small"
        value={macroIndex}
        onChange={(change) => actions.updateMacroIndex(parseInt(change))}
        min="0"
        max="99"
       />
       <H2RInput
        type="number"
        label="Media player"
        size="small"
        value={mediaPlayer}
        onChange={(change) => actions.updateMediaPlayer(parseInt(change))}
        min="1"
        max="4"
       />

       <InputWithInsetLabel
        value={useStills}
        label="Use stills"
        insetLabel={`to ${useStills + 9}`}
        min={1}
        max={71}
        onChange={(change) => actions.updateUseStills(change)}
       />

       <Toggle
        label="User wait"
        enabled={userWait}
        setEnabled={(change) => {
         actions.updateStingerUserWait(change);
        }}
       />
      </div>
     </div>
    </>
   )}
  </div>
 );
}

const InputWithInsetLabel = ({
 value,
 label,
 min,
 max,
 onChange,
 insetLabel,
 disabled,
}) => {
 return (
  <div className="flex flex-col w-full">
   <span
    className="mb-0.5 text-gray-500 tracking-wide font-semibold
                   text-xs 
                   "
   >
    {label}
   </span>
   <div
    className={`leading-none rounded transition duration-300 ease-in-out focus:outline-none focus-within:ring focus-within:ring-main-500
    text-sm px-2 py-1 bg-gray-700 whitespace-nowrap
      ${disabled ? "opacity-40" : "opacity-100"}
    `}
   >
    <input
     type="number"
     className="bg-transparent focus:outline-none w-[2rem]"
     value={value}
     min={min}
     max={max}
     disabled={disabled}
     onChange={(e) => {
      let c;
      if (e.target.value === null) {
       c = min;
      } else {
       c = parseInt(e.target.value);
      }
      onChange(c || min);
     }}
    />
    <span className="text-gray-500">{insetLabel}</span>
   </div>
  </div>
 );
};

function classNames(...classes) {
 return classes.filter(Boolean).join(" ");
}

const Toggle = ({ label, enabled, setEnabled }) => {
 return (
  <div className="flex flex-col">
   <span className="mb-0.5 text-gray-500 tracking-wide font-semibold text-xs  ">
    {label}
   </span>
   <Switch
    checked={enabled}
    onChange={setEnabled}
    className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-6 w-10 cursor-pointer bg-gray-600 focus:outline-none focus:ring-2 focus:ring-main-500"
   >
    <span className="sr-only">Use setting</span>
    <span
     aria-hidden="true"
     className="pointer-events-none absolute bg-transparent w-full h-full rounded-md"
    />
    <span
     aria-hidden="true"
     className={classNames(
      enabled ? "bg-main-500" : "bg-gray-600",
      "pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"
     )}
    />
    <span
     aria-hidden="true"
     className={classNames(
      enabled ? "translate-x-5" : "translate-x-0",
      "pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-600 rounded-full bg-gray-500 shadow transform ring-0 transition-transform ease-in-out duration-200"
     )}
    />
   </Switch>
  </div>
 );
};
