import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";

import { stingers } from "../store/definitions";
import * as actions from "../store/actions";

function classNames(...classes) {
 return classes.filter(Boolean).join(" ");
}

export default function StingersDropdown() {
 let stingerType = useSelector((state) => state.stingerType);

 return (
  <Listbox
   value={stingerType}
   onChange={(change) => actions.updateStingerType(change)}
  >
   {({ open }) => (
    <>
     <Listbox.Label className="block text-gray-500 tracking-wide font-semibold text-xs">
      Stinger type
     </Listbox.Label>
     <div className="mt-1 relative">
      <Listbox.Button className="relative w-full bg-gray-700 border border-gray-700 rounded shadow-sm pl-2 pr-10 py-0 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-main-500 focus:border-main-500 sm:text-sm">
       <span className="flex items-center">
        <img
         src={stingers[stingerType].steps[4]}
         alt=""
         className="flex-shrink-0 w-6 aspect-video"
        />
        <span className="ml-3 block truncate">
         {stingers[stingerType].label}
        </span>
       </span>
       <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
       </span>
      </Listbox.Button>

      <Transition
       show={open}
       as={Fragment}
       leave="transition ease-in duration-100"
       leaveFrom="opacity-100"
       leaveTo="opacity-0"
      >
       <Listbox.Options className="absolute z-10 mt-1 w-full bg-gray-700 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
        {Object.entries(stingers).map(([s, stinger]) => (
         <Listbox.Option
          key={s}
          className={({ active }) =>
           classNames(
            active ? "text-gray-100 bg-main-600" : "text-gray-300",
            "cursor-default select-none relative py-2 pl-3 pr-9"
           )
          }
          value={s}
         >
          {({ selected, active }) => (
           <>
            <div className="flex items-center">
             <img
              src={stingers[s].steps[4]}
              alt=""
              className="flex-shrink-0 w-6 aspect-video"
             />
             <span
              className={classNames(
               selected ? "font-semibold" : "font-normal",
               "ml-3 block truncate"
              )}
             >
              {stinger.label}
             </span>
            </div>

            {selected ? (
             <span
              className={classNames(
               active ? "text-white" : "text-main-600",
               "absolute inset-y-0 right-0 flex items-center pr-4"
              )}
             >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
             </span>
            ) : null}
           </>
          )}
         </Listbox.Option>
        ))}
       </Listbox.Options>
      </Transition>
     </div>
    </>
   )}
  </Listbox>
 );
}
