import PropTypes from "prop-types";

const H2RButton = ({
 text = "",
 colour = "main",
 size = "large",
 icon = "",
 type = "button",
 css = "",
 onClick,
 disabled = false,
}) => {
 let iconBefore;

 switch (icon) {
  case "cog":
   iconBefore = (
    <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M9.192 2.536c-.304-1.248-2.08-1.248-2.384 0a1.226 1.226 0 01-1.829.759c-1.098-.67-2.353.587-1.685 1.684a1.227 1.227 0 01-.757 1.83c-1.25.303-1.25 2.08 0 2.382a1.226 1.226 0 01.757 1.83c-.668 1.097.587 2.353 1.685 1.685a1.225 1.225 0 011.83.757c.303 1.25 2.08 1.25 2.382 0a1.226 1.226 0 011.83-.757c1.097.668 2.353-.588 1.684-1.685a1.226 1.226 0 01.758-1.83c1.249-.303 1.249-2.08 0-2.382a1.225 1.225 0 01-.757-1.83c.668-1.097-.588-2.353-1.685-1.684a1.226 1.226 0 01-1.83-.758zM8 10.4a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z"
    />
   );
   break;

  case "multiview":
   iconBefore = (
    <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M4 3.2a2.4 2.4 0 00-2.4 2.4v4.8A2.4 2.4 0 004 12.8h8a2.4 2.4 0 002.4-2.4V5.6A2.4 2.4 0 0012 3.2H4zm-.8 7.2v-.8h4v1.6H4a.8.8 0 01-.8-.8zm5.6.8H12a.8.8 0 00.8-.8v-.8h-4v1.6zm0-3.2h4V5h-4v3zM7.2 5h-4v3h4V5z"
     fill="currentColor"
    />
   );
   break;

  case "pencil":
   iconBefore = (
    <path
     d="M10.869 2.869A1.6 1.6 0 1113.13 5.13l-.634.635-2.263-2.263.635-.634zM9.103 4.634L2.4 11.338V13.6h2.262l6.704-6.703-2.264-2.263h.001z"
     fill="currentColor"
    />
   );
   break;

  case "tick":
   iconBefore = (
    <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="M13.366 4.234a.8.8 0 010 1.132l-6.4 6.4a.8.8 0 01-1.132 0l-3.2-3.2a.8.8 0 011.132-1.132L6.4 10.07l5.835-5.835a.8.8 0 011.13 0z"
     fill="currentColor"
    />
   );
   break;

  case "locked":
   iconBefore = (
    <path
     d="M8 10v1.333V10zm-4 4h8a1.334 1.334 0 001.333-1.333v-4A1.334 1.334 0 0012 7.333H4a1.333 1.333 0 00-1.333 1.334v4A1.333 1.333 0 004 14zm6.667-6.667V4.667a2.667 2.667 0 10-5.334 0v2.666h5.334z"
     stroke="currentColor"
     fill="none"
     strokeWidth="2"
     strokeLinecap="round"
     strokeLinejoin="round"
    />
   );
   break;

  case "unlocked":
   iconBefore = (
    <path
     d="M5.333 7.333V4.667A2.667 2.667 0 019.886 2.78M8 10v1.333M4 14h8a1.334 1.334 0 001.333-1.333v-4A1.334 1.334 0 0012 7.333H4a1.333 1.333 0 00-1.333 1.334v4A1.333 1.333 0 004 14z"
     stroke="currentColor"
     fill="none"
     strokeWidth="2"
     strokeLinecap="round"
     strokeLinejoin="round"
    />
   );
   break;

  case "plus":
   iconBefore = (
    <path
     d="M8 8H4m4-4v4-4zm0 4v4-4zm0 0h4-4z"
     stroke="currentColor"
     strokeWidth="2"
     strokeLinecap="round"
     strokeLinejoin="round"
    />
   );
   break;

  case "minus":
   iconBefore = (
    <path
     d="M13.333 8H2.667"
     stroke="currentColor"
     strokeWidth="2"
     strokeLinecap="round"
     strokeLinejoin="round"
    />
   );
   break;

  case "x":
   iconBefore = (
    <path
     d="M4 4l8 8m-8 0l8-8-8 8z"
     stroke="currentColor"
     strokeWidth="2"
     strokeLinecap="round"
     strokeLinejoin="round"
    />
   );
   break;

  case "desktop":
   iconBefore = (
    <path
     d="M6.5 11.333l-.5 2-.667.667h5.334L10 13.333l-.5-2h-3zm-3.167 0h9.334A1.333 1.333 0 0014 10V3.333A1.334 1.334 0 0012.667 2H3.333A1.333 1.333 0 002 3.333V10a1.333 1.333 0 001.333 1.333z"
     stroke="currentColor"
     fill="none"
     strokeWidth="2"
     strokeLinecap="round"
     strokeLinejoin="round"
    />
   );
   break;

  case "external":
   iconBefore = (
    <path
     d="M6.667 4H4a1.333 1.333 0 00-1.333 1.333V12A1.333 1.333 0 004 13.333h6.667A1.334 1.334 0 0012 12V9.333M9.333 2.667h4m0 0v4m0-4L6.667 9.333"
     stroke="currentColor"
     fill="none"
     strokeWidth="2"
     strokeLinecap="round"
     strokeLinejoin="round"
    />
   );
   break;

  case "double_arrow_right":
   iconBefore = (
    <path
     d="M8.667 3.333L13.333 8l-4.666 4.667M3.333 3.333L8 8l-4.667 4.667"
     stroke="currentColor"
     fill="none"
     strokeWidth="2"
     strokeLinecap="round"
     strokeLinejoin="round"
    />
   );
   break;

  case "refresh":
   iconBefore = (
    <path
     d="M3.055 6h-.388V2.667L3.055 6zm0 0a5.334 5.334 0 0110.237 1.333M3.055 6H6m6.946 4h.387v3.333L12.946 10zm0 0A5.336 5.336 0 012.708 8.667M12.946 10H10"
     stroke="currentColor"
     fill="none"
     strokeWidth="2"
     strokeLinecap="round"
     strokeLinejoin="round"
    />
   );
   break;

  case "download":
   iconBefore = (
    <path
     d="M2.667 10.666v.667a2 2 0 0 0 2 2h6.666a2 2 0 0 0 2-2v-.667M10.667 8 8 10.666m0 0L5.333 8M8 10.666v-8"
     stroke="currentColor"
     fill="none"
     strokeWidth="1.5"
     strokeLinecap="round"
     strokeLinejoin="round"
    />
   );
   break;

  default:
   iconBefore = "";
 }
 return (
  <button
   onClick={onClick}
   disabled={disabled}
   className={`${css} w-full sm:w-auto flex justify-center items-center leading-none uppercase font-semibold tracking-wide text-gray-100 rounded transition duration-300 ease-in-out focus:outline-none focus:ring
						${size === "small" ? "text-sm px-2 py-2" : "text-lg px-4 py-2"}
			
						${
       colour === "main" &&
       type === "button" &&
       "bg-main-500 hover:bg-main-400 focus:ring-main-500"
      }
	                    ${
                      colour === "dark" &&
                      type === "button" &&
                      "bg-gray-700 hover:bg-gray-600 focus:ring-main-500"
                     }

                        ${
                         colour === "main" &&
                         type === "link" &&
                         "inline-flex text-main-400 border-b border-transparent bg-transparent hover:text-main-300 focus:ring-main-400"
                        }

                        ${
                         colour === "dark" &&
                         type === "link" &&
                         "inline-flex text-sm text-gray-600 border-b border-transparent bg-transparent hover:text-gray-500 focus:ring-gray-500"
                        }
						${disabled && "opacity-25 cursor-auto"}

	`}
  >
   {icon && (
    <span className="flex justify-center items-center align-middle">
     <svg
      className="w-4 h-4 text-gray-100"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
     >
      {iconBefore}
     </svg>
    </span>
   )}
   {text !== "" && <span className="ml-1">{text}</span>}
  </button>
 );
};

H2RButton.propTypes = {
 text: PropTypes.string.isRequired,
 colour: PropTypes.string,
 icon: PropTypes.string,
 type: PropTypes.string,
 css: PropTypes.string,
 onClick: PropTypes.func.isRequired,
};

export default H2RButton;
