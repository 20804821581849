import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";

const faqs = [
 {
  question: "Tell me more about how this works!",
  answer: `The downloaded file will contain an image sequence and a macro. These images will then be loaded into your ATEMs Media Pool. When you run the Macro, the following will happen automatically…
  1. Set up your DSK to work with the images.
  2. Set up a colour generator to the correct colour.
  3. Turn on the DSK and run through each of the 10 images.
  4. Cut to whatever was on preview.
  5. (Optionally) hold for the specified amount of time.
  6. Reverse back down through the images.
  7. Turn off the DSK.`,
 },
 {
  question: "Why is this free?",
  answer:
   "This tool, like many others from Here to Record, is free because I like it that way. You are more than welcome to support this project by purchasing any of the packs, otherwise just enjoy it and use it for your shows.",
 },
 {
  question: "Will this work with other switchers?",
  answer:
   "Perhaps. I have one tested this with the Blackmagic Design ATEM, however it is just a collection of images and a macro that “plays” them back in a certain order.",
 },
 {
  question: "Can I have more control over which DSK, Macro, etc is used?",
  answer:
   "Yes, use the cog above - next to the download button - to make some adjustments to your final macro.",
 },
 {
  question: "I don’t like using a solid colour, what other options do I have?",
  answer:
   "I get it - the solid colour won’t always work out. The good news is that you can use a custom image as opposed to the solid colour. Just put the image you want in your media pool and make some tweaks to use it, as opposed to the colour generator!",
 },
 {
  question: "Is there anything I should be cautious about?",
  answer:
   "The downloaded XML file may overwrite a Macro and images in your Media Pool so it’s best to proceed with caution there. Otherwise you are good to go.",
 },
 {
  question: "Why use the Media Pool when I could just play a video?",
  answer:
   "Good question, and this is of course still possible. The reason for using the Media Pool in this instance is that all the images are already loaded onto your ATEM and ready to roll when you are. No need to go find the media card, load it into your player, nor rely on external apps to playback any videos.",
 },
 {
  question: "Are there any usage restrictions?",
  answer:
   "Nope, feel free to use this tool to get stingers for any of your productions. The only rule is that selling of stingers - obtained freely through this app, or purchased via a downloaded pack - is not allowed.",
 },
 {
  question: "Where did the idea come from?",
  answer:
   "Special thanks to Ryan Somerfield for this one. He used this method during our Return Feed live series, and I couldn’t help borrowing the idea and running with it.",
  link: "https://www.youtube.com/c/RyanSomerfield/",
  linkText: "See his great work here",
 },
];

function classNames(...classes) {
 return classes.filter(Boolean).join(" ");
}

export default function FAQs() {
 return (
  <div className="pb-20">
   <div className="">
    <div className="prose lg:prose-xl max-w-5xl divide-y-2 divide-gray-200">
     <h2>Tell me about it...</h2>
     <dl className="mt-6 space-y-6 divide-y divide-gray-200">
      {faqs.map((faq) => (
       <Disclosure as="div" key={faq.question} className="pt-6">
        {({ open }) => (
         <>
          <dt className="text-lg">
           <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
            <span className="font-medium text-gray-900">{faq.question}</span>
            <span className="ml-6 h-7 flex items-center">
             <ChevronDownIcon
              className={classNames(
               open ? "-rotate-180" : "rotate-0",
               "h-6 w-6 transform"
              )}
              aria-hidden="true"
             />
            </span>
           </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
           <p className="text-base text-gray-500 whitespace-pre-wrap">
            {faq.answer}{" "}
            {faq.link && (
             <a href={faq.link} target="_blank" rel="noreferrer">
              {faq.linkText}.
             </a>
            )}
           </p>
          </Disclosure.Panel>
         </>
        )}
       </Disclosure>
      ))}
     </dl>
    </div>
   </div>
  </div>
 );
}
