import H2RButton from "./h2r-components/H2RButton";
import { stingers } from "../store/definitions";
import Layout, { SectionWrapped } from "./Layout";

const packs = [
 {
  id: 1,
  name: "Stills Pack",
  fileType: "",
  description: "UHD4K PNG image sequences of all stingers.",
  image: stingers["circle-to-center"].steps[3],
  link:
   "https://heretorecord.lemonsqueezy.com/checkout/buy/99e2c92e-a2b3-4077-b0f1-7fd628c95295",
 },
 {
  id: 2,
  name: "MP4 Pack",
  fileType: "",
  description: "MP4 video files of all stingers in 1080p60.",
  image: stingers["diagonal"].steps[5],
  link:
   "https://heretorecord.lemonsqueezy.com/checkout/buy/fc20c978-a677-4643-adbb-46544a014a27",
 },
 {
  id: 3,
  name: "WebM Pack",
  fileType: "",
  description: "WebM video files of all stingers in 2160p60.",
  image: stingers["bars"].steps[6],
  link:
   "https://heretorecord.lemonsqueezy.com/checkout/buy/3fc08478-3305-4a5a-bb16-35589b15297d",
 },
 {
  id: 4,
  name: "ProRes Pack",
  fileType: "",
  description: "UHD4K ProRes 4444 files with Alpha.",
  image: stingers["falling-opacity"].steps[6],
  link:
   "https://heretorecord.lemonsqueezy.com/checkout/buy/61bc032d-a9c5-4f97-84c1-77865e50708f",
 },
 {
  id: 5,
  name: "Everything Pack",
  fileType: "",
  description: "PNGs, MP4s, WebMs and ProRes.",
  image: stingers["circle-from-center"].steps[5],
  link:
   "https://heretorecord.lemonsqueezy.com/checkout/buy/8c18e060-d1b1-4b1a-a8ff-9ae95ac1023c",
 },
];

export default function Support() {
 return (
  <Layout>
   <SectionWrapped bgColour="bg-gray-100">
    <SupportContent />
   </SectionWrapped>
  </Layout>
 );
}

export const SupportContent = () => {
 return (
  <div className="py-10 ">
   <h1 className="text-3xl text-main-500">Support this project</h1>
   <p className="mt-1 text-gray-500">
    Grab stinger packs in PNG, MOV, WebM and MP4!
   </p>
   <div className="mt-5 flex flex-col md:flex-row mx-auto w-100 justify-between space-y-2 md:space-y-0 md:space-x-4">
    {packs.map((pack) => {
     return (
      <div
       key={pack.id}
       className="px-2 pt-2 pb-2 md:pb-5 flex flex-row md:flex-col items-center md:items-center w-full bg-gray-800 rounded md:text-center"
      >
       <div
        style={{
         backgroundImage: `url(${pack.image})`,
         backgroundSize: "cover",
        }}
        className=" aspect-video min-w-[4rem] md:w-full rounded border-2 border-gray-400 opacity-50 hover:opacity-100 transition-opacity duration-200"
       ></div>
       <div className="ml-6 md:ml-0 flex flex-col items-start md:items-center grow">
        <span className="md:mt-4 text-base sm:text-lg font-bold text-main-300 ">
         {pack.name}
        </span>
        <span className="mt-1 text-xs sm:text-sm text-gray-200">
         {pack.description}
        </span>
       </div>
       <span className="mt-.5 text-xs text-gray-400 md:grow">
        Includes 10 stingers.
       </span>
       <div className="md:mt-4 mr-5 ml-2 md:mr-0 w-100">
        <a href={pack.link} target="_blank" rel="noreferrer">
         <H2RButton
          text="Get"
          icon="external"
          size="small"
          css="w-max"
          onClick={() => null}
         />
        </a>
       </div>
      </div>
     );
    })}
   </div>
  </div>
 );
};
