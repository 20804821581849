import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import * as action from "../store/actions";
import H2RButton from "./h2r-components/H2RButton";

export default function Macro() {
 const [macro, setMacro] = useState("");
 const [media, setMedia] = useState("");
 const [expanded, setExpanded] = useState(false);

 let macroIndex = useSelector((state) => state.macroIndex);
 let colourGenerator = useSelector((state) => state.colourGenerator);
 let stingerColour = useSelector((state) => state.stingerColour);
 let dsk = useSelector((state) => state.dsk);
 let mediaPlayer = useSelector((state) => state.mediaPlayer);
 let useStills = useSelector((state) => state.useStills);
 let stingerWait = useSelector((state) => state.stingerWait);
 let userWait = useSelector((state) => state.userWait);

 useEffect(() => {
  let m = `<Macro index="${macroIndex}" name="Run Stinger" description="Created with H2R Stingers">
    <Op id="ColorGeneratorHue" colorGeneratorIndex="${
     colourGenerator - 1
    }" hue="${stingerColour.h}"/>
    <Op id="ColorGeneratorSaturation" colorGeneratorIndex="${
     colourGenerator - 1
    }" saturation="${stingerColour.s}"/>
    <Op id="ColorGeneratorLuminescence" colorGeneratorIndex="${
     colourGenerator - 1
    }" luminescence="${stingerColour.l}"/>
    <Op id="DownstreamKeyFillInput" keyIndex="${
     dsk - 1
    }" input="Color${colourGenerator}"/>
    <Op id="DownstreamKeyCutInput" keyIndex="${
     dsk - 1
    }" input="MediaPlayer${mediaPlayer}Key"/>
    <Op id="DownstreamKeyMaskEnable" keyIndex="${dsk - 1}" enable="False"/>
    <Op id="DownstreamKeyPreMultiply" keyIndex="${
     dsk - 1
    }" preMultiply="False"/>
    <Op id="DownstreamKeyClip" keyIndex="${dsk - 1}" clip="0.429993"/>
    <Op id="DownstreamKeyGain" keyIndex="${dsk - 1}" gain="0.229996"/>
    <Op id="DownstreamKeyOnAir" keyIndex="${dsk - 1}" onAir="True"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills - 1}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 1}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 2}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 3}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 4}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 5}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 6}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 7}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 8}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="CutTransition" mixEffectBlockIndex="0"/>
    ${
     userWait
      ? '<Op id="MacroUserWait"/>'
      : `<Op id="MacroSleep" frames="${stingerWait}"/>`
    }
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 7}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 6}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 5}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 4}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 3}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 2}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills + 1}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="MediaPlayerSourceStillIndex" mediaPlayer="${
     mediaPlayer - 1
    }" index="${useStills - 1}"/>
    <Op id="MediaPlayerSourceStill" mediaPlayer="${mediaPlayer - 1}"/>
    <Op id="MacroSleep" frames="1"/>
    <Op id="DownstreamKeyOnAir" keyIndex="${dsk - 1}" onAir="False"/>
</Macro>`;
  setMacro(m);
  action.updateMacroXML(m);
 }, [
  macroIndex,
  colourGenerator,
  stingerColour,
  dsk,
  mediaPlayer,
  useStills,
  stingerWait,
  userWait,
 ]);

 useEffect(() => {
  let mp = `<MediaPool>
     <Stills>
         <Still index="${useStills - 1}" name="1" path="ATEM Media Pool/1.png"/>
         <Still index="${useStills}" name="2" path="ATEM Media Pool/2.png"/>
         <Still index="${useStills + 1}" name="3" path="ATEM Media Pool/3.png"/>
         <Still index="${useStills + 2}" name="4" path="ATEM Media Pool/4.png"/>
         <Still index="${useStills + 3}" name="5" path="ATEM Media Pool/5.png"/>
         <Still index="${useStills + 4}" name="6" path="ATEM Media Pool/6.png"/>
         <Still index="${useStills + 5}" name="7" path="ATEM Media Pool/7.png"/>
         <Still index="${useStills + 6}" name="8" path="ATEM Media Pool/8.png"/>
         <Still index="${useStills + 7}" name="9" path="ATEM Media Pool/9.png"/>
         <Still index="${
          useStills + 8
         }" name="10" path="ATEM Media Pool/10.png"/>
     </Stills>
 </MediaPool>


`;
  setMedia(mp);
  action.updateMediaPoolXML(mp);
 }, [useStills]);

 return (
  <div
   className={`relative 
            ${!expanded ? "h-24 overflow-hidden" : "h-100"}
  `}
  >
   <div
    className={`absolute bottom-0 w-full 
            ${expanded ? "" : " h-full bg-gray-800/90"}
   `}
   >
    <div className="pb-2 flex justify-center items-end h-full">
     <H2RButton
      text={expanded ? "Collapse" : "Expand"}
      size="small"
      colour="dark"
      onClick={() => {
       setExpanded(!expanded);
      }}
     />
    </div>
   </div>
   <span className="block pt-4 mb-2 text-sm uppercase text-gray-400">
    Macro
   </span>
   <textarea
    className="mb-10 h-80 px-2 py-2 w-full bg-gray-700 text-gray-300 rounded"
    defaultValue={macro}
    disabled={true}
   />
   <span className="block mb-2 text-sm uppercase text-gray-400">
    Media pool
   </span>
   <textarea
    className="mb-10 h-80 px-2 py-2 w-full bg-gray-700 text-gray-300 rounded"
    defaultValue={media}
    disabled={true}
   />
  </div>
 );
}
